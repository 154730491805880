import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'


class CategoryTemplate extends React.Component {
  render() {
    const posts = get(this.props, 'data.allContentfulBlogPost').nodes

        const { currentPage, numPages, title } = this.props.pageContext
    // const isFirst = currentPage === 1
    // const isLast = currentPage === numPages
    // const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    // const nextPage = (currentPage + 1).toString()
    return (
      <Layout location={this.props.location}>
        <Seo title={title} />
        <Hero title={title} />
        <ArticlePreview posts={posts} />
        {/* {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Previous Page
          </Link>
        )}
        {!isLast && (
          <Link to={nextPage} rel="next">
            Next Page →
          </Link>
        )} */}
      </Layout>
    )
  }
}

export default CategoryTemplate

export const pageQuery = graphql`
  query($category: String!) {
    allContentfulBlogPost(
      limit: 2000
      filter: {category: { slug: {eq: $category}}, node_locale: { eq: "hu-HU" }}
    ) {
      totalCount
      nodes {
        slug
        publishDate(formatString: "YYYY. MM. DD.")
        category {
          name
        }
        heroImage {
          gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 424, height: 212)
        }
        description {
          raw
        }
      }
    }
  }
`